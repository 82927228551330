import React, { Component } from "react";
import { DiscussionEmbed } from "disqus-react";
import urljoin from "url-join";
import config from "../../../data/SiteConfig";

class Disqus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toasts: []
    };

  }

  
  render() {
    const siteConfig = config;
    const post = this.props.postNode.frontmatter;
    const disqusShortname = siteConfig.disqusShortname;
    const disqusConfig = {
      identifier: post.id,
      title: post.title,
    };

    const { postNode } = this.props;
    if (!config.disqusShortname) {
      return null;
    }

    return (
      <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
    );
  }
}

export default Disqus;
