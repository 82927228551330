import React, { Component } from "react";
import Link  from "gatsby-link";
import { Follow } from "react-twitter-widgets";
import "./UserInfo.css";

class UserInfo extends Component {
  render() {
    const config = this.props.config;
    const { userTwitter } = config;
    const { expanded } = this.props;
     return (
      <div className="user-info">
      <small>Written by <Link to="/about"><strong>{config.userName}</strong></Link> </small>
      </div>
    );
  }
}

export default UserInfo;
